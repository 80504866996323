import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

function Headline({ title, description, backTo, actionContent }) {
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                {
                    backTo 
                    && 
                    <Button component={Link} to={backTo} startIcon={<ArrowBackIcon />}>
                        Tillbaka
                    </Button>
                }
                {actionContent}
            </Grid>
            <Typography component='h1' variant='h4' sx={{ my: 1.5 }}>
                {title}
            </Typography>
            {
                description
                &&
                <Typography component='p' color="text.secondary" sx={{ my: 1.5 }}>
                    {description}
                </Typography>
            }
        </>
    )
}

export default Headline;
