import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import Alert from '../../components/Alert';
import Input from '../../components/Input';
import { createConversation } from '../../api/conversations';

function Create() {
    const user = useSelector(state => state.auth.user);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const handleError = (msg) => {
        setError(msg);
        setTimeout(() => setError(''), 3000);
    }

    const handleReset = () => {
        setTitle('');
        setDescription('');
        setLoading(false);
    }

    const handleSubmit = async () => {
        if (title.length < 2) return handleError('Ange en intern titel');
        setLoading(true);

        await createConversation(title, description, user);

        handleReset();
        setDone(true);
    }

    if (done) {
        return (
            <>
                <DialogTitle>
                    Klart!
                </DialogTitle>
                <DialogContent>
                    Stäng ned fönstret och klicka på samtalsöppnaren i tabellen bakom för att
                    gå in och arbeta med den.
                </DialogContent>
            </>
        );
    }

    return (
        <>
            <DialogTitle>
                Skapa en samtalsöppnare
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} columns={1} sx={{ pt: 1 }}>
                    <Grid item xs={1}>
                        <Input
                            required
                            autoFocus
                            label="Intern rubrik"
                            fullWidth
                            value={title}
                            onChange={event => setTitle(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Eventuell intern beskrivning"
                            fullWidth
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Skapa
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <Alert message={error} />
        </>
    )
}

export default Create;
