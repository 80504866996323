import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import Headline from '../components/Headline';
import Users from '../components/settings/Users';
import Invites from '../components/settings/Invites';

function SettingsUsersPage() {
    const user = useSelector(state => state.auth.user);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Headline
                    backTo='/settings'
                    title='Användare'
                    description='Det finns två olika roller: administratörer och moderatorer. Till skillnad från en administratör, kan inte en moderator hantera föreningens inställningar eller användarinställningar. I övrigt är dessa två snarlika.'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Users user={user} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Invites user={user} />
            </Grid>
        </Grid>
    )
}

export default SettingsUsersPage;
