import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Grid from '@mui/material/Grid';

import { getSubjects, updateSubjectInteractions } from '../api/conversations';

import Loading from '../components/Loading';
import NothingFound from '../components/NothingFound';
import EndButton from '../components/subjectPreview/EndButton';
import Subject from '../components/subjectPreview/Subject';

function ConversationPreviewPage() {
    const { id } = useParams();
    const [subjects, setSubjects] = useState(null);
    const [chosenSubject, setChosenSubject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleClick = async (subject) => {
        setSubmitLoading(true);
        await updateSubjectInteractions(subject);
        setChosenSubject(subject);
        setSubmitLoading(false);
    }

    useEffect(() => {
        const fetchSubjects = async () => {
            const c = await getSubjects(id);
            setSubjects(c);
            setLoading(false);
        }
        
        fetchSubjects();
    }, [id]);

    const cardHeight = 150;

    if (loading) {
        return <Loading />;
    }

    if (subjects.length === 0) {
        return <NothingFound message={`Ni har inte skapat valbara ämnen än.`} />
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {
                subjects.map(subject => 
                    <Subject 
                        cardHeight={cardHeight}
                        submitLoading={submitLoading}
                        handleClick={handleClick}
                        chosenSubject={chosenSubject}
                        key={subject.id} 
                        subject={subject}
                    />
                )
            }
            {
                chosenSubject 
                && 
                <EndButton 
                    setChosenSubject={setChosenSubject} 
                    chosenSubject={chosenSubject}
                />
            }
        </Grid>
    )
}

export default ConversationPreviewPage;
