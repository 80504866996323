import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { blue, green } from '@mui/material/colors';

import Dots from './Dots';

function Subject({
    subject,
    chosenSubject,
    handleClick,
    cardHeight,
    submitLoading
}) {
    const isChosen = (chosenSubject && chosenSubject.id === subject.id);
    const hasInformation = !!subject.information;

    return (
        <Grid item xs={12} md={6} style={{}}>
            <Card style={{
                position: 'relative',
                minHeight: cardHeight,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                backgroundColor: (isChosen) ? green[500] : blue[500],
            }}>
                {chosenSubject && <Dots cardHeight={cardHeight} amount={subject.interactionsCount} />}
                <Button
                    disabled={submitLoading}
                    onClick={() => handleClick(subject)}
                    style={{
                        textTransform: 'none',
                        flex: 1,
                        minHeight: 150,
                        height: '100%',
                        cursor: 'pointer',
                        color: 'white',
                        fontSize: isChosen
                            ? hasInformation ? '1rem' : '1.8rem'
                            : '1.8rem',
                    }}
                >
                    {
                        isChosen
                            ? hasInformation ? subject.information : subject.name
                            : subject.name
                    }
                </Button>
            </Card>
        </Grid>
    )
}

export default Subject;
