import {
    doc,
    getDoc,
    deleteDoc,
    updateDoc,
    collection,
    addDoc,
    query,
    where,
    getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';


const updateUser = async (user, name) => {
    await updateDoc(doc(db, 'users', user.uid), {
        name,
    });
}

const updateUserAdmin = async (user, isAdmin) => {
    await updateDoc(doc(db, 'users', user.uid), {
        isAdmin,
    });
}

const getUsers = async (user) => {
    const q = query(
        collection(db, 'users'),
        where('organizationId', '==', user.organizationId),
    );

    const querySnapshot = await getDocs(q);
    const data = [];

    querySnapshot.forEach(doc => data.push({
        uid: doc.id,
        ...doc.data(),
    }));

    return data;
}

const inviteUser = async (name, email, organizationId) => {
    return await addDoc(collection(db, 'invites'), {
        organizationId,
        name,
        email,
        isAdmin: false,
        code: Math.floor(100000 + Math.random() * 900000),
        redeemed: false,
    })
}

const getInvite = async (id) => {
    const docRef = await getDoc(doc(db, 'invites', id))
        .catch(e => window.location.href = '/settings');

    return {
        id: docRef.id,
        ...docRef.data(),
    }
}

const deleteInvite = async (id) => {
    if (getInvite(id)) {
        await deleteDoc(doc(db, 'invites', id));
    }
}


export {
    updateUser,
    inviteUser,
    deleteInvite,
    getUsers,
    updateUserAdmin
};
