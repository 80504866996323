import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

function TopBanner({ message }) {
    return (
        <Box style={{
            backgroundColor: blue[500],
            textAlign: 'center',
            padding: 5,
        }}>
            <Typography component='p' style={{
                color: 'white',
                textAlign: 'center',
                fontSize: '1rem'
            }}>
                {message}
            </Typography>

        </Box>
    )
}

export default TopBanner;
