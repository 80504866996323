import { Box, Grid } from '@mui/material';
import React from 'react';

function AuthForm({ children, handleSubmit }) {
    return (
        <Box 
            component='form'
            noValidate
            onSubmit={async (e) => {
                e.preventDefault();
                await handleSubmit();
            }}
        >
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                style={{ minHeight: '100vh' }}
            >
                <Box
                    style={{ width: '400px' }}
                >
                    <Grid
                        container
                        direction='column'
                        spacing={2}
                    >
                        {children}
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default AuthForm;
