import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import PeopleIcon from '@mui/icons-material/People';

import BigBox from '../components/BigBox';
import Headline from '../components/Headline';

function SettingsPage() {
    const user = useSelector(state => state.auth.user);

    const pageDescription = user.isAdmin 
        ? 'Hantera både ditt personliga konto och även föreningens uppgifter.'
        : 'Hantera dina personliga inställningar.';

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Headline
                    backTo='/'
                    title='Inställningar'
                    description={pageDescription}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <BigBox
                    to='/settings/personal'
                    Icon={PersonIcon}
                    title='Dina inställningar'
                    description='Hantera din profilinformation.'
                />
            </Grid>
            {
                user.isAdmin
                &&
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <BigBox
                            to='/settings/organization'
                            Icon={DomainIcon}
                            title='Föreningen'
                            description='Hantera föreningens information.'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <BigBox
                            to='/settings/users'
                            Icon={PeopleIcon}
                            title='Användare'
                            description='Hantera inbjudningar och nuvarande användare'
                        />
                    </Grid>
                </>
            }
        </Grid>
    )
}

export default SettingsPage;
