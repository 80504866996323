import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { updateUserAdmin } from '../../api/user';
import { db } from '../../firebase';

import CardHeader from '../CardHeader';
import Alert from '../Alert';
import NothingFound from '../NothingFound';

function Users({ user }) {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchInvites = async () => {
            const q = query(
                collection(db, 'users'),
                where('organizationId', '==', user.organizationId),
            );

            onSnapshot(q, (querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        uid: doc.id,
                        ...doc.data()
                    });
                });

                setUsers(data);
            });
        }

        fetchInvites();
    }, [user]);

    const handleDelete = (u) => {
        if (u.uid === user.uid) return;

        if (window.confirm('Är du helt säker på detta? Det går inte att ångra!')) {
            setError('Raderad')
        }
    }

    const toggleAdmin = async (u, isAdmin) => {
        if (u.uid === user.uid) return;
        await updateUserAdmin(u, isAdmin);
        setError(isAdmin ? 'Nu är användaren en administratör' : 'Nu är användaren en moderator')
    }

    const renderUsersList = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width='60%'>
                            Namn
                        </TableCell>
                        <TableCell align='center'>
                            Admin
                        </TableCell>
                        <TableCell align='center'>
                            &nbsp;
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(u => (
                        <TableRow key={u.uid}>
                            <TableCell>
                                <Typography fontWeight='bold'>
                                    {u.name}
                                </Typography>
                                <Typography color='text.secondary'>
                                    {u.email}
                                </Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Switch 
                                    defaultChecked={u.isAdmin} 
                                    disabled={u.uid === user.uid} 
                                    onClick={() => toggleAdmin(u, !u.isAdmin)}
                                />
                            </TableCell>
                            <TableCell align='center'>
                                <Button 
                                    color='error' 
                                    onClick={() => handleDelete(u)}
                                    disabled={true}
                                >
                                    <DeleteOutlineIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <Card>
            <CardHeader title='Nuvande användare' subheader='Hantera de personer som har tillgång till ert föreningskonto. Radering av en användare lanseras inom kort.' />
            {
                users.length === 0
                    ? <NothingFound message='Inga användare?' />
                    : renderUsersList()
            }
            <Alert message={error} type='success' />
        </Card>
    )
}

export default Users;
