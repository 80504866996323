import { 
    increment, 
    collection, 
    addDoc, 
    getDoc, 
    doc, 
    getDocs, 
    where, 
    orderBy, 
    query, 
    deleteDoc, 
    updateDoc 
} from 'firebase/firestore';
import { db } from '../firebase';

const createConversation = async (name, description, user) => {
    return await addDoc(collection(db, 'conversations'), {
        active: false,
        organizationId: user.organizationId,
        user: {
            uid: user.uid,
            name: user.name,
        },
        name,
        description,
        subjectsCount: 0,
        interactionsCount: 0,
    })
}

const getConversation = async (id) => {
    const docRef = await getDoc(doc(db, 'conversations', id))
        .catch(e => window.location.href = '/404');

    return {
        id: docRef.id,
        ...docRef.data(),
    }
}

const deleteConversation = async (conversation) => {
    if (getConversation(conversation.id)) {
        await deleteDoc(doc(db, 'conversations', conversation.id));
    }
}

const getSubjects = async (id) => {
    const q = query(
        collection(db, 'subjects'),
        where('conversationId', '==', id),
        orderBy('name', 'asc')
    );

    const querySnapshot = await getDocs(q);
    const data = [];

    querySnapshot.forEach(doc => data.push({
        id: doc.id,
        ...doc.data(),
    }));

    return data;
}

const getSubject = async (id) => {
    const docRef = await getDoc(doc(db, 'subjects', id))
        .catch(e => window.location.href = '/404');

    return {
        id: docRef.id,
        ...docRef.data(),
    }
}

const deleteSubject = async (id) => {
    if (getSubject(id)) {
        await deleteDoc(doc(db, 'subjects', id));
    }
}

const createSubject = async (name, conversation, user) => {
    return await addDoc(collection(db, 'subjects'), {
        organizationId: user.organizationId,
        conversationId: conversation.id,
        user: {
            uid: user.uid,
        },
        name,
        information: null,
        interactionsCount: 0,
    })
}

const updateSubject = async (id, data) => {
    if (getSubject(id)) {
        await updateDoc(doc(db, 'subjects', id), {
            information: data.information,
        });
    }
}

const updateSubjectInteractions = async (subject) => {
    if (getSubject(subject.id)) {
        await updateDoc(doc(db, 'subjects', subject.id), {
            interactionsCount: increment(1),
        });
        await updateDoc(doc(db, 'conversations', subject.conversationId), {
            interactionsCount: increment(1),
        });
    }
}

const getComments = async (id) => {
    const docRef = await getDoc(doc(db, 'conversationComments', id))
        .catch(e => window.location.href = '/404');

    return {
        id: docRef.id,
        ...docRef.data(),
    }
}

const createComment = async (subject, comment) => {
    if (getConversation(subject.conversationId)) {
        return await addDoc(collection(db, 'conversationComments'), {
            subject: {
                id: subject.id,
                name: subject.name,
            },
            conversationId: subject.conversationId,
            comment,
        })
    }
}

export {
    createConversation,
    deleteConversation,
    getConversation,
    createSubject,
    getSubjects,
    deleteSubject,
    updateSubject,
    updateSubjectInteractions,
    getComments,
    createComment,
};
