import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import { db } from '../firebase';

import Headline from '../components/Headline';
import ConversationsList from '../components/conversation/ConversationsList';
import Modal from '../components/Modal';
import Create from '../components/conversation/Create';

function ConversationsPage() {
    const [conversations, setConversations] = useState([]);
    const user = useSelector(state => state.auth.user);

    const actionButton = props => <Button 
        variant='contained' 
        startIcon={<AddIcon />}
        {...props}
    >
        Skapa en ny
    </Button>;

    const actionContent = <Modal Trigger={actionButton}>
        <Create />
    </Modal>;

    const pageDescription = `
        ${user.name.split(' ')[0]}, 
        en samtalsöppnare är en bräda med valbara alternativ. Tanken är att när du står på torg 
        eller knackar dörr så ska väljaren du pratar med kunna välja på din surfplatta eller mobil det ämnet 
        som hen anser är viktigt. Denna digitala bräda ger er också stöd i samtalet genom så kallade 
        informationskort.
    `;

    useEffect(() => {
        const q = query(
            collection(db, 'conversations'), 
            where('organizationId', '==', user.organizationId),
            orderBy('name', 'asc')
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({
                    id: doc.id, 
                    ...doc.data()
                });
            });
            
            setConversations(data);
        });

        return unsubscribe;
    }, [user]);

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={1}>
                <Headline 
                    backTo='/'
                    actionContent={actionContent}
                    title='Samtalsöppnare'
                    description={pageDescription}
                />
            </Grid>

            <Grid item xs={12}>
                <ConversationsList items={conversations} />
            </Grid>
        </Grid>
    )
}

export default ConversationsPage;
