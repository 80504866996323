import {
    collection,
    addDoc,
    setDoc,
    getDocs,
    doc,
    getDoc,
    updateDoc,
    query,
    where,
} from 'firebase/firestore';
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut 
} from 'firebase/auth';

import { db, auth } from '../firebase';

const checkInvite = async (email, code) => {
    try {
        const q = query(
            collection(db, 'invites'),
            where('email', '==', email),
            where('code', '==', parseInt(code, 10)),
            where('redeemed', '==', false)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) return null;

        let invite = {};
        querySnapshot.forEach((doc) => {
            invite = {
                id: doc.id,
                ...doc.data()
            }
        });

        return invite;
    } catch (error) {
        return error;
    }
}

const createUser = async (type, data) => {
    try {
        const { code, organization, name, email, password } = data;
        let organizationId = null;
        let invite = null;

        // Check if invite exists
        if (type === 'invite') {
            invite = await checkInvite(email, code);
            
            if (!invite) {
                throw new Error('Felaktig inbjudningskod');
            }

            organizationId = invite.organizationId;
        }

        const uid = await createUserWithEmailAndPassword(auth, email, password)
            .then(userCredentials => userCredentials.user.uid);

        // User ref
        const userDocRef = doc(db, 'users', uid);
        await setDoc(userDocRef, {
            name,
            email,
            isAdmin: type ==='invite' ? false : true,
        });

        // Only create organization when creating new account
        if (type === 'new') {
            const docRef = await addDoc(collection(db, 'organizations'), { 
                name: organization 
            });
            organizationId = docRef.id;
        }

        // Update user with organizationId
        updateDoc(userDocRef, {
            organizationId,
        });

        // Redeem invite
        if (type === 'invite') {
            await updateDoc(doc(db, 'invites', invite.id), { redeemed: true });
        }

        return {
            name,
            email,
            isAdmin: type === 'invite' ? false : true,
            organizationId,
            uid,
        }
    }
    catch (error) {
        return new Error('Det gick inte att skapa kontot');
    }
}

const getUserData = async (uid) => {
    const docSnap = await getDoc(doc(db, 'users', uid));

    if (!docSnap.exists()) return signOut(auth);

    const userData = docSnap.data();

    return {
        uid: uid,
        name: userData.name,
        organizationId: userData.organizationId,
        email: userData.email,
        isAdmin: userData.isAdmin,
    };
}

const loginUser = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then(async userCredential => {
            const user = userCredential.user;

            return await getUserData(user.uid);
        })
        .catch(error => {
            return error;
        })
}

const logoutUser = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        return error;
    });
}

export { createUser, loginUser, logoutUser, getUserData };
