import React from 'react';
import Card from '@mui/material/Card';

import { useSelector } from 'react-redux';

import CardHeader from '../CardHeader';
import Create from '../subjects/Create';
import SubjectsList from '../subjects/SubjectsList';

function Subjects({ conversation, subjects }) {
    const user = useSelector(state => state.auth.user);

    return (
        <Card>
            <CardHeader title='Ämnen' />
            <Create 
                user={user} 
                conversation={conversation}
            />
            <SubjectsList
                items={subjects}
                conversation={conversation}
                user={user}
            />
        </Card>
    )
}

export default Subjects;
