import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey'
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DialpadIcon from '@mui/icons-material/Dialpad';

import { createUser } from '../api/auth';
import { login } from '../features/authSlice';

import AuthForm from '../components/AuthForm';
import Input from '../components/Input';
import Alert from '../components/Alert';

function RegisterPage() {
    const [organization, setOrganization] = useState('');
    const [type, setType] = useState('new');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleError = (msg) => {
        setError(msg);
        setTimeout(() => setError(''), 3000);
    }

    const handleSubmit = async () => {
        setError('');

        if (type === 'new') {
            if (organization.length < 6) return handleError('Ange organizationens namn');
        } else {
            if (code.length !== 6 || isNaN(code)) return handleError('Enge en korrekt kod');
        }
        if (name.length < 6 || name.toString().indexOf('error') > -1 || name.toString().indexOf(' ') === -1) return handleError('Ange ditt för- och efternamn');
        if (email.length < 6) return handleError('Ange din mejladress');
        if (password.length < 6) return handleError('Ange ett lösenord');
        if (password !== passwordRepeat) return handleError('Lösenordet stämmer inte överens');
        
        setLoading(true);
        await createUser(type, { code, organization, name, email, password })
            .then(res => {
                if (res.name === 'Error') {
                    setError(res.message || 'Registreringen misslyckades')
                } else {
                    dispatch(login(res));
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }

    const handleType = () => {
        setType(type === 'new' ? 'invite' : 'new');
    }

    return (
        <AuthForm handleSubmit={handleSubmit}>
            <Grid item>
                <Typography variant='h5' component='h1'>
                    {type === 'new' ? 'Skapa ett föreningskonto' : 'Skapa ett personligt konto'}
                </Typography>
                <Typography component='p' mb={2} color={grey[600]}>
                    {
                        type === 'new'
                        ? <>Skapa ett konto åt din förening med dig själv som administratör.</>
                        : <>Ange inbjudningskoden och dina uppgifter för att skapa ett personligt konto.</>
                    }
                </Typography>
                {
                    type === 'new'
                        ? <Button fullWidth onClick={handleType} variant='outlined'>Jag har blivit inbjuden</Button>
                        : <Button fullWidth onClick={handleType} variant='outlined'>Jag vill skapa ett föreningskonto</Button>
                }
            </Grid>
            <Grid item>
                {
                    type === 'new'
                        ? <Input
                            label='Föreningens namn'
                            icon={<LocationCityIcon />}
                            required
                            value={organization}
                            onChange={(event) => setOrganization(event.target.value)}
                        />
                        : <Input
                            label='Inbjudningskoden (6 siffror)'
                            icon={<DialpadIcon />}
                            required
                            type='number'
                            min={6}
                            max={6}
                            value={code}
                            onChange={(event) => setCode(event.target.value)}
                        />
                }
                
            </Grid>
            <Grid item>
                <Input
                    label='Ditt för- och efternamn'
                    icon={<PersonIcon />}
                    required={true}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Input
                    label='Din mejladress'
                    icon={<AlternateEmailIcon />}
                    type='email'
                    required={true}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Input
                    label='Lösenord'
                    icon={<PasswordIcon />}
                    type='password'
                    required={true}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Input
                    label='Upprepa lösenordet'
                    icon={<PasswordIcon />}
                    type='password'
                    required={true}
                    value={passwordRepeat}
                    onChange={(event) => setPasswordRepeat(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Button
                    fullWidth
                    variant='contained'
                    type='submit'
                    disabled={loading}
                >
                    Skapa
                </Button>
            </Grid>
            <Grid item>
                <Button
                    fullWidth
                    variant='outlined'
                    to='/'
                    component={Link}
                    startIcon={<ArrowBackIcon />}
                >
                    Logga in istället
                </Button>
            </Grid>
            <Grid item>
                <Button
                    fullWidth
                    variant='outlined'
                    to='/policy'
                    component={Link}
                    endIcon={<ArrowForwardIcon />}
                >
                    Policy, avtal och GDPR
                </Button>
            </Grid>
            <Alert message={error} />
        </AuthForm>
    )
}

export default RegisterPage;
