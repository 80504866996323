import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import grey from '@mui/material/colors/grey'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AuthForm from '../components/AuthForm';

function PolicyPage() {
    return (
        <AuthForm>
            <Grid item>
                <Typography variant='h5' component='h1'>
                    Policy, avtal och GDPR
                </Typography>
                <Typography component='p' mb={2} color={grey[600]}>
                    Avtalet är enkelt. Har du frågor? Mejla mig.
                </Typography>
            </Grid>
            <Card>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Tjänsterna är till för Liberalernas olika föreningar.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Tjänsterna är kostnadsfria.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                All information lagras inom EU.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Ingen information delas med tredje part.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Lösenord sparas aldrig i klartext.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Du har rätt att bli glömd, mejla mig så hjälper jag dig.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Vi spårar inga beteenden, interaktioner, eller något annat som du aldrig 
                                gett något direkt medgivande till.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Du får inte sälja, hyra ut eller på annat sätt bruka tjänsten 
                                på annat sätt än vad den är menad för.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Missbruk eller olämpligt användande kan leda till avstängning.
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
            <Grid item>
                <Button
                    fullWidth
                    variant='outlined'
                    to='/register'
                    component={Link}
                    startIcon={<ArrowBackIcon />}
                >
                    Tillbaka
                </Button>
            </Grid>
        </AuthForm>
    )
}

export default PolicyPage;
