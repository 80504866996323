import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { red, green, yellow, blue } from '@mui/material/colors'

function Alert({ message, type='error' }) {
    const [open, setOpen] = useState(false);

    const types = {
        'info': {},
        'error': {
            backgroundColor: red[500],
            color: 'white',
        },
        'success': {
            backgroundColor: green[500],
            color: 'white',
        },
        'primary': {
            backgroundColor: blue[500],
            color: 'white',
        },
        'warning': {
            backgroundColor: yellow[600],
            color: 'white',
        },
    }

    useEffect(() => {
        setOpen(!!message);
        setTimeout(() => setOpen(false), 3000);
    }, [message]);

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <SnackbarContent 
                style={types[type]}
                message={message}
            />
        </Snackbar>
    )
}

export default Alert;
