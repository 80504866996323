import React from 'react';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import CardHeader from '../CardHeader';
import NothingFound from '../NothingFound';

function Stats({ subjects = [] }) {
    if (subjects.length === 0) {
        return <Card>
            <CardHeader title='Statistik' />
            <NothingFound message='Det finns inga ämnen' />
        </Card>
    }

    const totalInteractions = Object.keys(subjects).reduce(function (previous, key) {
        return previous + subjects[key].interactionsCount;
    }, 0);

    return (
        <Card>
            <CardHeader title='Statistik' />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width='60%'>Ämne</TableCell>
                            <TableCell align="center">Interaktioner</TableCell>
                            <TableCell align="center">Procent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subjects.map((subject) => (
                            <TableRow
                                key={subject.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {subject.name}
                                </TableCell>
                                <TableCell align="center">
                                    {subject.interactionsCount} st
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        subject.interactionsCount > 0
                                            ? ((subject.interactionsCount / totalInteractions) * 100).toFixed(2)
                                            : 0
                                    }%
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default Stats;
