import React from 'react';
import grey from '@mui/material/colors/grey';
import CardHeader from '@mui/material/CardHeader';

function CustomCardHeader(props) {
    return (
        <CardHeader style={{
            paddingBottom: 15,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: grey[200],
        }} {...props} />
    )
}

export default CustomCardHeader;
