import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Input from '../../components/Input';
import { updateSubject } from '../../api/conversations';

function InformationForm({ subject }) {
    const [information, setInformation] = useState(subject.information || '');
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const handleReset = () => {
        setInformation('');
        setLoading(false);
    }

    const handleSubmit = async () => {
        setLoading(true);

        await updateSubject(subject.id, { information });

        handleReset();
        setDone(true);
    }

    if (done) {
        return (
            <>
                <DialogTitle>
                    Sparat!
                </DialogTitle>
            </>
        );
    }

    return (
        <>
            <DialogTitle>
                Informationskort för "{subject.name}"
            </DialogTitle>
            <DialogContent>
                <Typography>
                    När man väljer ett ämne kommer denna information att visas på skärmen.
                    Informationen underlättar samtalet med väljaren.
                </Typography>
                <Grid container spacing={2} columns={1} sx={{ pt: 1 }}>
                    <Grid item xs={1}>
                        <Input
                            multiline
                            label="T.ex. stödord eller vallöften"
                            fullWidth
                            value={information}
                            onChange={event => setInformation(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Uppdatera
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    )
}

export default InformationForm;
