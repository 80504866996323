import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Loading() {
    return (
        <Box>
            <Typography variant="caption" display="block" gutterBottom>
                Laddar
            </Typography>
        </Box>
    )
}

export default Loading;
