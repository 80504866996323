import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { db } from '../../firebase';
import { deleteInvite } from '../../api/user';

import CardHeader from '../CardHeader';
import NothingFound from '../NothingFound';
import InviteForm from './InviteForm';

function Users({ user }) {
    const [invites, setInvites] = useState([]);

    useEffect(() => {
        const fetchInvites = async () => {
            const q = query(
                collection(db, 'invites'),
                where('organizationId', '==', user.organizationId),
            );

            onSnapshot(q, (querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                setInvites(data);
            });
        }

        fetchInvites();
    }, [user])

    const handleDelete = async (id) => {
        if (window.confirm('Är du säker?')) {
            await deleteInvite(id);
        }
    }

    const renderUsersList = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width='60%'>
                            Namn
                        </TableCell>
                        <TableCell align='center'>
                            Aktiverad?
                        </TableCell>
                        <TableCell align='center'>
                            Återkalla
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invites.map(u => (
                        <TableRow key={u.id}>
                            <TableCell>
                                <Typography fontWeight='bold'>
                                    {u.name}
                                </Typography>
                                <Typography color='text.secondary'>
                                    {u.email}
                                </Typography>
                                {
                                    !u.redeemed
                                    &&
                                    <Typography color='text.secondary'>
                                        Inbjudningskod: {u.code}
                                    </Typography>
                                }
                            </TableCell>
                            <TableCell align='center'>
                                {u.redeemed ? 'Ja' : 'Nej'}
                            </TableCell>
                            <TableCell align='center'>
                                <Button
                                    color='error'
                                    onClick={() => handleDelete(u.id)}
                                    disabled={u.redeemed}
                                >
                                    <DeleteOutlineIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <Card>
            <CardHeader title='Bjud in personer' subheader='Du behöver du mejla koden manuellt till den du bjuder in. Hen får därefter gå in på hemsidan och skapa konto med koden.' />
            <CardContent>
                <InviteForm user={user} />

                {
                    invites.length === 0
                        ? <NothingFound message='Inga inbjudningar' />
                        : renderUsersList()
                }
            </CardContent>
        </Card>
    )
}

export default Users;
