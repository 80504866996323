import React from 'react';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import CardHeader from '../CardHeader';
import NothingFound from '../NothingFound';

function Comments({ items }) {

    if (items.length === 0) {
        return (
            <Card>
                <CardHeader title='Inspel från väljare' />
                <NothingFound message='Det finns inga inspel från väljare.' />
            </Card>
        )
    }

    return (
        <Card>
            <CardHeader title='Inspel från väljare' />
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow
                                key={item.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell width='80%'>
                                    <div style={{ color: '#777' }}>
                                        Valt ämne: {item.subject.name}
                                    </div>
                                    <div>
                                        {item.comment}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default Comments;
