import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import grey from '@mui/material/colors/grey'

import { loginUser } from '../api/auth';
import { login } from '../features/authSlice';

import AuthForm from '../components/AuthForm';
import Input from '../components/Input';
import Alert from '../components/Alert';
import { useDispatch } from 'react-redux';

function LoginPage() {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleError = (msg) => {
        setError(msg);
        setTimeout(() => setError(''), 3000);
    }

    const handleSubmit = async () => {
        setError('');

        if (email.length < 6) return handleError('Ange din mejladress');
        if (password.length < 6) return handleError('Ange ditt lösenord');
        setLoading(true);

        await loginUser(email, password)
            .then(res => {
                if (res.code) {
                    setError('Inloggningen misslyckades')
                } else {
                    dispatch(login(res));
                }
                setLoading(false);
            });
    }

    return (
        <AuthForm handleSubmit={handleSubmit}>
            <Grid item>
                <Typography variant='h5' component='h1'>
                    Välkommen
                </Typography>
                <Typography component='p' mb={2} color={grey[600]}>
                    Logga in på ditt personliga konto.
                </Typography>
            </Grid>
            <Grid item>
                <Input 
                    label='Mejladress' 
                    icon={<AlternateEmailIcon />}
                    type='email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Input 
                    label='Lösenord' 
                    icon={<PasswordIcon />}
                    type='password'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
            </Grid>
            <Grid item>
                <Button 
                    fullWidth
                    variant='contained' 
                    type='submit'
                    disabled={loading}
                >
                    Logga in
                </Button>
            </Grid>
            <Grid item>
                <Button 
                    fullWidth
                    variant='outlined' 
                    to='/register'
                    component={Link}
                >
                    Skapa ett konto
                </Button>
            </Grid>

            <Alert message={error} />
        </AuthForm>
    )
}

export default LoginPage;
