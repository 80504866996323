import React from 'react';
import FaceIcon from '@mui/icons-material/Face';
import { Box, Typography } from '@mui/material';

function NothingFound({ message }) {
    return (
        <Box sx={{ 
            justifyContent: 'center', 
            textAlign: 'center', 
            backgroundColor: 'white',
            padding: 5,
        }}>
            <Box>
                <FaceIcon fontSize='large' sx={{ mb: 2 }} />
            </Box>
            <Typography component='div' color='text.secondary'>
                {message}
            </Typography>
        </Box>
    )
}

export default NothingFound;
