import React from 'react';
import { InputAdornment, TextField } from '@mui/material';

function Input(props) {
    let { 
        icon,
        fullWidth=true,
        type='text', 
    } = props;

    return (
        <TextField
            style={{ backgroundColor: 'white' }}
            variant='outlined'
            type={type}
            fullWidth={fullWidth}
            {...props}
            InputProps={icon && {
                startAdornment: (
                    <InputAdornment position="start">
                        {icon}
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default Input;
