import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SettingsIcon from '@mui/icons-material/Settings';
import BorderInnerIcon from '@mui/icons-material/BorderInner';

import { logout } from '../features/authSlice';
import { logoutUser } from '../api/auth';

import BigBox from '../components/BigBox';
import Headline from '../components/Headline';

function DashboardPage() {
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const handleLogout = () => {
        if (window.confirm('Är du säker?')) {
            dispatch(logout());
            logoutUser();
        }
    }

    const actionContent = <>
        <Box></Box>
        <Button variant='contained' color='error' onClick={handleLogout}>
            Logga ut
        </Button>
    </>;

    const pageDescription = `
        Mitt namn är Shadi Domat och jag kommer att utveckla och vidareutveckla funktioner kontinuerligt.
        Därför uppskattar jag att du hör av dig till mig med dina idéer, synkpunkter
        och förslag. Du når mig via shadi.domat@liberalerna.se!
    `;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Headline
                    title={`${user.name.split(' ')[0]},`}
                    actionContent={actionContent}
                    description={pageDescription}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <BigBox
                    to='/conversations'
                    Icon={RecordVoiceOverIcon}
                    title='Samtalsöppnare'
                    description='Kan användas på torg eller när man knackar dörr där väljaren får välja samtalsämnet.'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <BigBox
                    to='/'
                    Icon={BorderInnerIcon}
                    title='BINGO'
                    inactive
                    description='Bjud in din förening att spela BINGO! Alla får varsin lott i mobilen. Ni programleder digitalt.'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <BigBox
                    to='/settings'
                    Icon={SettingsIcon}
                    title='Inställningar'
                    description='Hantera föreningens och dina personliga inställningar. Bjud in och hantera användare.'
                />
            </Grid>
        </Grid>
    )
}

export default DashboardPage;
