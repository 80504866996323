import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

export default function Modal({ Trigger, children }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Trigger onClick={handleClickOpen} />
            <Dialog open={open} onClose={handleClose}>
                {children}
                <DialogActions>
                    <Button onClick={handleClose}>Stäng</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
