import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import Input from '../Input';

import { inviteUser } from '../../api/user';

function InviteForm({ user }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (name.length < 2) return;
        setLoading(true);

        await inviteUser(name, email, user.organizationId);
        setName('');
        setEmail('');
        setLoading(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1} style={{ 
                backgroundColor: '#f5f5f5',
                paddingTop: 5,
                paddingRight: 5,
                paddingBottom: 10,
                marginTop: 5,
                marginBottom: 5,
            }}>
                <Grid item sm={12}>
                    <Input
                        required
                        label='För- och efternamn'
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Input
                        required
                        label='Mejladress'
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Button
                        disabled={loading}
                        variant='contained'
                        type='submit'
                        startIcon={<AddIcon />}
                    >
                         Bjud in
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default InviteForm;
