import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged, signOut } from '@firebase/auth';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { doc, onSnapshot } from '@firebase/firestore';

import './App.css';
import { login, logout } from './features/authSlice';
import { auth, db } from './firebase';
import { getUserData } from './api/auth';

import Error404Page from './pages/Error404Page';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import PolicyPage from './pages/PolicyPage';

import DashboardPage from './pages/DashboardPage';
import AuthForm from './components/AuthForm';
import ConversationPage from './pages/ConversationPage';
import ConversationsPage from './pages/ConversationsPage';
import ConversationPreviewPage from './pages/ConversationPreviewPage';
import SettingsPage from './pages/SettingsPage';
import SettingsPersonalPage from './pages/SettingsPersonalPage';
import SettingsOrganizationPage from './pages/SettingsOrganizationPage';
import SettingsUsersPage from './pages/SettingsUsersPage';
import TopBanner from './components/TopBanner';

function App() {
  const [disableApp, setDisableApp] = useState(false);
  const [globalMessage, setGlobalMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, async (u) => {
      if (u) {
        const uObj = await getUserData(u.uid);
        dispatch(login(uObj));
      } else {
        dispatch(logout());
      }

      setLoading(false);
    });

    const fetchAppInfo = async () => {
      return onSnapshot(doc(db, 'app', 'global'), docRef => {
        const doc = docRef.data();
        setDisableApp(doc.disableApp);
        setGlobalMessage(doc.message);
      });
    }

    fetchAppInfo();
  }, [dispatch]);

  if (disableApp) {
    signOut(auth);
    dispatch(logout());

    return (
      <AuthForm>
        <Typography component='p' color={grey[800]} style={{
          textAlign: 'center',
        }}>
          {globalMessage ?? 'Appen är tillfälligt avstängd för underhåll. För frågor, kontakta mig på shadi.domat@liberalerna.se'}
        </Typography>
      </AuthForm>
    );
  }

  if (loading) {
    return (
      <AuthForm>
        <Typography component='p' color={grey[800]} style={{ 
          textAlign: 'center',
          fontSize: '1.5rem'
        }}>
          Laddar häftiga grejer...
        </Typography>
      </AuthForm>
    );
  }

  return (
    <Router>
      <>
        {
          globalMessage
          &&
          <TopBanner message={globalMessage} />
        }
        <Container style={{ padding: 15 }}>

          {
            user
              ?
              <Switch>
                <Route path='/settings/personal' exact component={SettingsPersonalPage} />
                <Route path='/settings/organization' exact component={SettingsOrganizationPage} />
                <Route path='/settings/users' exact component={SettingsUsersPage} />
                <Route path='/settings' exact component={SettingsPage} />
                <Route path='/conversations' exact component={ConversationsPage} />
                <Route path='/conversations/:id' component={ConversationPage} />
                <Route path='/404' exact component={Error404Page} />
                <Route path='/co/:id' component={ConversationPreviewPage} />
                <Route path='/' component={DashboardPage} />
              </Switch>
              :
              <Switch>
                <Route path='/' exact component={LoginPage} />
                <Route path='/register' exact component={RegisterPage} />
                <Route path='/policy' exact component={PolicyPage} />
                <Route path='/co/:id' component={ConversationPreviewPage} />
              </Switch>
            }
        </Container>
      </>
    </Router>
  );
}

export default App;
