import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { deleteSubject } from '../../api/conversations';

import Modal from '../Modal';
import NothingFound from '../NothingFound';
import InformationForm from './InformationForm';

function SubjectsList({ items = [], conversation, user }) {
    if (items.length === 0) {
        return <NothingFound message='Det finns inga ämnen' />
    }

    const handleDelete = async (id) => {
        if (window.confirm('Är du säker på att du vill radera ämnet?')) {
            await deleteSubject(id, conversation, user);
        }
    }

    const actionButton = props => <Button
        {...props}
    >
        <MenuBookIcon />
    </Button>;

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow
                                key={item.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell width='80%'>
                                    {item.name}
                                </TableCell>
                                <TableCell>
                                    <Modal Trigger={actionButton}>
                                        <InformationForm subject={item} />
                                    </Modal>
                                </TableCell>
                                <TableCell>
                                    <Button color='error'>
                                        <DeleteOutlineIcon onClick={() => handleDelete(item.id)} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SubjectsList;
