import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import Headline from '../components/Headline';
import Personal from '../components/settings/Personal';

function SettingsPersonalPage() {
    const user = useSelector(state => state.auth.user);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Headline
                    backTo='/settings'
                    title='Dina inställningar'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Personal user={user} />
            </Grid>
        </Grid>
    )
}

export default SettingsPersonalPage;
