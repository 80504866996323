import {
    getDoc,
    doc,
    updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase';

const getOrganization = async (id) => {
    const docRef = await getDoc(doc(db, 'organizations', id))
        .catch(e => window.location.href = '/404');

    return {
        id: docRef.id,
        ...docRef.data(),
    }
}

const updateOrganization = async (id, name) => {
    if (getOrganization(id)) {
        await updateDoc(doc(db, 'organizations', id), {
            name,
        });
    }
}


export {
    getOrganization,
    updateOrganization,
};
