import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import Alert from '../Alert';
import CardHeader from '../CardHeader';
import Input from '../Input';

import { getOrganization, updateOrganization } from '../../api/organization';

function Organization({ user }) {
    const [name, setName] = useState(null);
    const [error, setError] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [loading, setLoading] = useState(true);

    const handleSubmit = async () => {
        setError('');
        setAlertType('error');

        if (name.length < 6) return setError('Ange föreningens namn');

        await updateOrganization(user.organizationId, name);
        setAlertType('success');
        setError('Sparat!');
    }

    useEffect(() => {
        const fetchOrg = async () => {
            const org = await getOrganization(user.organizationId);
            setName(org.name);
            setLoading(false);
        }

        fetchOrg();
    }, [user]);

    return (
        <Card>
            <CardHeader title='Föreningens uppgifter' />
            <CardContent>
                {
                    loading 
                    ? <></>
                    : <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Input
                                    required
                                    label='Föreningens namn'
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' onClick={handleSubmit}>
                                    Uppdatera
                                </Button>
                            </Grid>
                        </Grid>
                }
            </CardContent>
            <Alert message={error} type={alertType} />
        </Card>
    )
}

export default Organization;
