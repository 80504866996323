import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import NothingFound from '../NothingFound';

function List({ items = [] }) {
    if (items.length === 0) {
        return <NothingFound message='Det finns inga samtalsöppnare' />
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width='65%'>Namn</TableCell>
                            <TableCell align="center">Interaktioner</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow
                                key={item.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    <Link to={`/conversations/${item.id}`}>
                                        {item.name}
                                    </Link>
                                    {
                                        item.description
                                        &&
                                        <Typography color='text.secondary' fontSize='small'>
                                            {item.description}
                                        </Typography>
                                    }
                                </TableCell>
                                <TableCell align="center">{item.interactionsCount || 0}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default List;
