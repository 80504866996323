import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { updateUser } from '../../api/user';

import Alert from '../Alert';
import CardHeader from '../CardHeader';
import Input from '../Input';

function Personal({ user }) {
    const [name, setName] = useState(user.name);
    const [error, setError] = useState('');
    const [alertType, setAlertType] = useState('error');

    const handleSubmit = async () => {
        setError('');
        setAlertType('error');

        if (name.length < 6) return setError('Ange ditt namn');

        await updateUser(user, name);
        setAlertType('success');
        setError('Sparat!');
    }

    return (
        <Card>
            <CardHeader title='Personliga uppgifter' />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Input 
                            required
                            label='Ditt för- och efternamn'
                            value={name}
                            onChange={event => setName(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' onClick={handleSubmit}>
                            Uppdatera
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <Alert message={error} type={alertType} />
        </Card>
    )
}

export default Personal;
