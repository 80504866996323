import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { createComment } from '../../api/conversations';

import Input from '../Input';
import Modal from '../Modal';

function EndButton({ setChosenSubject, chosenSubject }) {
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEndConveration = async () => {
        setChosenSubject(null);
        if (comment.trim().length > 0) {
            setLoading(true);
            await createComment(chosenSubject, comment);
            setLoading(false);
        }
        setComment('');
    }

    const renderForm = () => {
        return (
            <>
                <DialogTitle>
                    Har väljaren sagt något du vill ta med?
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ paddingTop: 10 }}>
                        <Grid item sm={12}>
                            <Input
                                value={comment}
                                onChange={event => setComment(event.target.value)}
                                label='En eventuell kommentar'
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Button 
                                variant='contained' 
                                onClick={handleEndConveration}
                                disabled={loading}
                            >
                                Spara och börja om
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </>
        )
    }

    return (
        <Grid item sm={12} textAlign='center' paddingY={5}>
            <Modal Trigger={
                props => <Button {...props}>
                    Börja om
                </Button>
            }>
                {renderForm()}
            </Modal>
        </Grid>
    )
}

export default EndButton;
