import React from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function BigBox({
    title,
    Icon,
    description,
    buttonText='Hantera',
    inactive=false,
    to='/'
}) {
    return (
        <Card>
            <CardContent sx={{ position: 'relative' }}>
                {
                    Icon && 
                    <Icon 
                        color='action'
                        fontSize='large' 
                        sx={{ 
                            position: 'absolute', 
                            right: 15, 
                            top: 10,
                        }} 
                    />
                }
                <Typography variant="h5" component="h2" sx={{ mb: 1.5 }}>
                    {title}
                </Typography>
                <Typography variant="body2">
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                {
                    inactive
                    ? <Button size="small" disabled>Under utveckling</Button>
                    : <Button size="small" component={Link} to={to}>{buttonText}</Button>
                }
                
            </CardActions>
        </Card>
    )
}

export default BigBox;
