import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAXCqeWUtzmj4H2girYlaDHOwJqOWmVLTc",
    authDomain: "legeringen.firebaseapp.com",
    projectId: "legeringen",
    storageBucket: "legeringen.appspot.com",
    messagingSenderId: "488099566140",
    appId: "1:488099566140:web:387a205aaeef5371e12939"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth(app);

export { app, db, auth };
