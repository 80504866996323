import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { Link } from 'react-router-dom';

import { db } from '../firebase';
import { getConversation, deleteConversation } from '../api/conversations';

import Headline from '../components/Headline';
import Loading from '../components/Loading';
import Stats from '../components/conversation/Stats';
import Subjects from '../components/conversation/Subjects';
import Comments from '../components/conversation/Comments';

function ConversationPage() {
    const { id } = useParams();
    const [conversation, setConversation] = useState(null);
    const [comments, setComments] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleDelete = async () => {
        if (window.confirm('Är du säker? Du förlorar ALLT och detta går INTE att ångra!')) {
            await deleteConversation(conversation);
            return window.location.href='/conversations';
        }
    }

    useEffect(() => {
        const fetchConversation = async () => {
            const c = await getConversation(id);
            setConversation(c);

            const q = query(
                collection(db, 'subjects'),
                where('organizationId', '==', c.organizationId),
                where('conversationId', '==', c.id),
                orderBy('name', 'asc')
            );

            onSnapshot(q, (querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                setSubjects(data);
            });

            await fetchComments();

            setLoading(false);
        }

        const fetchComments = async () => {
            const q = query(
                collection(db, 'conversationComments'),
                where('conversationId', '==', id),
            );

            onSnapshot(q, (querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                setComments(data);
            });
        }

        fetchConversation();
    }, [id])

    if (loading) {
        return <Loading />;
    }

    const actionContent = <>
        <Box></Box>
        <Box>
            <Button color='error' sx={{ mr: 3 }} onClick={handleDelete}>
                Radera
            </Button>
            <Button
                variant='contained'
                component={Link}
                target='_blank'
                to={`/co/${conversation.id}`}
                startIcon={<VisibilityIcon />}
            >
                Granska
            </Button>
        </Box>
    </>;

    return (
        <>
            <Headline 
                backTo='/conversations'
                title={conversation.name} 
                description='Lägg till ämnen som du vill ska vara valbara i samtalsöppnaren. Dessa sorteras i bokstavsordning.'
                actionContent={actionContent}
            />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Subjects 
                        subjects={subjects} 
                        conversation={conversation}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2} flexDirection='column'>
                        <Grid item xs={12}>
                            <Stats
                                subjects={subjects}
                                conversation={conversation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Comments items={comments} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ConversationPage;
