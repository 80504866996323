import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';

import Input from '../Input';

import { createSubject } from '../../api/conversations';

function Create({ conversation, user }) {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (name.length < 2) return;
        setLoading(true);

        await createSubject(name, conversation, user);
        setName('');
        setLoading(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormControl style={{
                display: 'flex',
                flexDirection: 'row',
                background: '#f5f5f5',
                padding: 10,
                borderBottom: '1px solid #eee',
            }}>
                <Input
                    required
                    label='Skapa ämne'
                    value={name}
                    onChange={event => setName(event.target.value)}
                />
                <Button
                    disabled={loading}
                    variant='contained'
                    style={{ marginLeft: 10 }}
                    type='submit'
                >
                    <AddIcon />
                </Button>
            </FormControl>
        </form>
    )
}

export default Create;
