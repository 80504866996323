import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Headline from '../components/Headline';
import { Link } from 'react-router-dom';

function Error404Page() {
    const pageDescription = `
        Något har gått fel. Antingen finns inte sidan du letar efter eller så har du försökt 
        nå en sida som du inte har behörighet att se.
    `;

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
                <Headline
                    title='Ajdå'
                    description={pageDescription}
                />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
                <Button variant='contained' component={Link} to='/'>
                    Börja om
                </Button>
            </Grid>
        </Grid>
    )
}

export default Error404Page;
