import React from 'react';
import { yellow } from '@mui/material/colors';

function Dots({cardHeight, amount}) {
    const dots = [];
    const dotSize = 20;
    const cardWidth = 400;

    const getRandom = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

    const randomPosition = () => {
        return {
            x: getRandom(0, cardWidth - dotSize) + 'px', // 👈🏼 Horizontally
            y: getRandom(0, cardHeight - dotSize) + 'px' // 👈🏼 Vertically
        }
    }

    for (let i = 0; i < amount; i++) {
        const position = randomPosition();

        dots.push(<div key={i + '_' + Math.random()} style={{
            position: 'absolute',
            left: position.x,
            top: position.y,
            width: dotSize,
            height: dotSize,
            backgroundColor: yellow[500],
            borderRadius: 9999,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: yellow[700],
        }} />);
    }

    return dots;
}

export default Dots;
